import React from 'react';
import Header from '../Header/Header';
import './Hero.css';
import hero_image from '../../assets/hero_image.png';
import hero_image_back from '../../assets/hero_image_back.png';
import Hearts from '../../assets/heart.png';
import calories from '../../assets/calories.png';
import NummberCounter from 'number-counter';
import { motion } from 'framer-motion';
import { Link } from "react-scroll";

const Hero = () => {

    const transition = {type: 'spring', duration : 3};
    const mobile = window.innerWidth <= 768 ? true: false;
    return (
        <div className="hero" id='home'>
            <div className="blur hero-blur"></div>
            <div className="left-h">
                <Header />
                {/* the best add */}
                <div className="the-best-ad">
                    <motion.div
                    initial={{left : mobile? "178px": '238px' }}
                    whileInview= {{left : "8px" }}
                    transition={{...transition, type: 'tween'}}
                    ></motion.div>
                    <span>The best fitness club in the town</span>
                </div>

                {/* hero heading */}
                <div className="hero-text">
                    <div>
                        <span className='stroke-text'>Shape </span>
                        <span>Your</span>
                        </div>
                        <div><span>
                            Ideal Body</span></div>
                <div><span>
                    In here we will help you to shape and build your ideal body and live up your life to fullest
                    </span>
            </div>
            </div>
            {/* figures */}
            <div className="figures">
                <div>
                    <span>
                        <NummberCounter end={140} start={100} delay='4' preFix="+"/>
                    </span>
                <span>Expert coaches</span>
                </div>
                <div>
                    <span>
                    <NummberCounter end={978} start={800} delay='4' preFix="+"/>
                    </span>
                <span>Members joined</span>
                </div>
                <div>
                    <span>
                    <NummberCounter end={50} start={0} delay='4' preFix="+"/>
                    </span>
                <span>fitness programs</span>
                </div>
            </div>
            {/* hero buttons */}
            <div className="hero-buttons">
                <buttons className="btn">Get Started</buttons>
                <buttons className="btn"><Link
                to='Reasons'
                span={true}
                smooth={true}
                >Learn More</Link></buttons>
            </div>
            </div>
            <div className="right-h">
                <button className='btn'><Link
                to='plans'
                span={true}
                smooth={true}
                >Join Now</Link></button>

                <motion.div 
                initial={{right : "-1rem"}}
                whileInView={{right: "4rem"}}
                transition={transition}
                 className="heart-rate">
                    <img src={Hearts} alt="" />
                    <span>Heart Rate</span>
                    <span>116 bpm</span>
                </motion.div>

                {/* hero images */}
                <img src={hero_image} alt="" className="hero-image" />
                <motion.img
                initial={{right: "11rem"}}
                whileInView={{right: "20rem"}}
                transition={transition}
                 src={hero_image_back} alt="" className="hero-image-back" />
            
            {/* calories */}
            <motion.div
            initial={{right : "37rem"}} 
            whileInView={{right: "28rem"}}
            transition={transition}
            className="calories">
                <img src={calories} alt="" />
                <div>
                <span>Calories Burned</span>
                <span>220 kcal</span>
                </div>
            </motion.div>
            </div>
        </div>
    );
}

export default Hero